import '../css/app.css'
import Alpine from 'alpinejs'
import baguetteBox from 'baguettebox.js'
// import './cookie3.js'

// ALPINE
window.Alpine = Alpine
Alpine.start()

document.addEventListener('DOMContentLoaded', function () {
  baguetteBox.run('.baguette', {
    noScrollbars: true,
    async: true,
  })
})
